import { browser } from "$app/environment";

import { get } from "svelte/store";

import useSWR from "./swr";
import type { IdentityProviderType } from "~proto/modal_server/proto/public_api";

export type User =
  | { logged_in: false }
  | {
      logged_in: true;
      user_id: string;
      username: string;
      is_admin: boolean;
      notification_email: string;
      approved: boolean;
      name: string;
      avatar_url: string;
      timezone: string | null;
      funnel_events: string[];
      last_used_workspace: string;
      identity_provider_type: IdentityProviderType;
    };

const { data, isValidating, mutate } = useSWR<User>("/api/user", {
  initialData: () => {
    if (browser) {
      try {
        const savedUser = localStorage.getItem("modal-user");
        return savedUser ? JSON.parse(savedUser) : undefined;
      } catch (e) {
        // A SecurityError is thrown if the user has "block third party cookies & site data" enabled.
        console.warn("Failed to parse user from localStorage", e);
        return undefined;
      }
    }
  },
  ignoreDestroy: true,
  refreshInterval: 60000,
});

if (browser) {
  data.subscribe((user) => {
    if (user) {
      localStorage.setItem("modal-user", JSON.stringify(user));

      // Store the last-login-method to help users remember how they last logged in on their browser.
      // We setItem here to only save after a successful login.
      if (user.logged_in && user.identity_provider_type) {
        localStorage.setItem("last-login-method", user.identity_provider_type);
      }
    }
  });
}

/** Inside a load() function, retrieve the current logged-in user. */
export async function getUserIsomorphic(
  fetch: typeof globalThis.fetch,
): Promise<User> {
  const cachedUser = get(data);
  if (cachedUser && cachedUser.logged_in) {
    // Client navigation, and user is already logged in.
    return cachedUser;
  } else {
    const res = await fetch("/api/user");
    if (!res.ok) {
      throw new Error(`Failed to fetch user: ${res.status}`);
    }
    return await res.json();
  }
}

export { data as user, isValidating, mutate };
